exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-akademia-tsx": () => import("./../../../src/pages/akademia.tsx" /* webpackChunkName: "component---src-pages-akademia-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-grafik-zajec-tsx": () => import("./../../../src/pages/grafik-zajec.tsx" /* webpackChunkName: "component---src-pages-grafik-zajec-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karnet-success-tsx": () => import("./../../../src/pages/karnet-success.tsx" /* webpackChunkName: "component---src-pages-karnet-success-tsx" */),
  "component---src-pages-karnet-tsx": () => import("./../../../src/pages/karnet.tsx" /* webpackChunkName: "component---src-pages-karnet-tsx" */),
  "component---src-pages-regulamin-i-polityka-prywatnosci-tsx": () => import("./../../../src/pages/regulamin-i-polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-regulamin-i-polityka-prywatnosci-tsx" */),
  "component---src-pages-uslugi-tsx": () => import("./../../../src/pages/uslugi.tsx" /* webpackChunkName: "component---src-pages-uslugi-tsx" */)
}

